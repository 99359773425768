<template>
  <transition name="modal">
    <div class="modal-mask" style="overflow-y: scroll;">
      <div v-if="getModalInfo" class="modal-container" @click.stop>

        <div class="disinter-main">
          <div class="disinter-inner">
            <p class="header">Önemli Bilgilendirme</p>
            <p class="paragraph1">
              Alıcı ve freelancer haklarını koruyan güvenli bir deneyim için; Bionluk üzerindeki mesajlaşmalarda
              <span style="color: #2d3640; font-weight: 600; text-decoration: underline;">iletişim ve site dışı ödeme bilgisi</span>
              paylaşmayacağını kabul etmiş olursun.
            </p>
            <br>
            <p class="paragraph2">
              Sesli iletişim kurmak istiyorsan websitemiz üzerinden ya da mobil uygulamalarımız üzerinden
              <span style="color: #2d3640; font-weight: 600; text-decoration: underline;">sesli arama</span> özelliğini kullanabilirsin.
            </p>
            <router-link to="/destek/odeme-guvenligi-47" class="paragraph-more" target="_blank">
              Daha fazla bilgi
            </router-link>
          </div>
          <div class="disinter-buttons-div">
            <div @click="backButton()" class="close-button" style="">Geri git</div>
            <div @click="accept()" class="okey-button" style="margin-left: 10px;">Anladım</div>
          </div>

        </div>
      </div>
    </div>
  </transition>


</template>

<script>

  export default {
    name: "src-pages-modals-disintermediation-v1_mobile",
    data() {
      return {
        comingFrom: null
      }
    },
    methods: {
      accept() {
        this.api.chat.chatDisintermediation()
          .then(({data}) => {
           // console.log(data);
          })
          .catch(err => {
            this.$toasted.error(err);
          })

        this.hide();
      },
      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },

      sendReport() {
        this.hide();
      },
      backButton() {
        if (this.comingFrom === "mobileChat") {
          this.routeBack("/", false);
        }
        this.hide();
      }
    },

    computed: {
      getModalInfo() {
        let comingFrom = this.$store.state.activeModal.info.comingFrom;
        if (comingFrom) {
          this.comingFrom = comingFrom;
        }
        return true;
      }
    }


  }

</script>

<style scoped lang="scss">
  .modal-container {
    margin-top: 60px;
    padding: 0;
    font-family: 'sofia-pro';
    overflow-y: scroll;
    width: calc(100vw - 30px) !important;
    max-width: 450px !important;
    height: calc(100vh - 50px) !important;
    max-height: 435px;
    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #ffffff;
    position: relative;
  }
  .disinter-main {
    padding: 20px;
  }

  .disinter-inner {

    .header {
      font-size: 24px;
      font-weight: 600;
      text-align: center;
      color: #2d3640;
      margin-bottom: 20px;
    }
    .paragraph1 {
      font-size: 15px;
      line-height: 1.3;
      text-align: left;

    }
    .paragraph2 {
      font-size: 15px;
      line-height: 1.3;
      text-align: left;
    }
    .paragraph-more {
      font-size: 14px;
      line-height: 1.5;
      text-align: left;
      color: #fd4056;
      cursor: pointer;
    }
  }

  .disinter-buttons-div {
    width: 230px;
    margin-top: 30px;
    margin-left: auto;
    display: flex;
    .close-button{
      width: 100px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      &:hover {
        color: #2d3640;
      }
    }
    .okey-button {
      width: 100px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 6px;
      border: 2px solid #FD4056;
      color: #FD4056;
      cursor: pointer;
      margin-left: 20px;
      &:hover {
        border: 2px solid #e94258;
        color: #e94258;
      }
    }
  }
</style>
